/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import SurveyResponseSection from "../SurveyResponseSection";

const TextFieldResponse = ({item, isLastItem, itemNumber, isDisabled, onSelectNextItem, onClick, onValueChange}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    onValueChange(item.id, value)
  }, [value])
  
  return (
    <SurveyResponseSection id={item.id} itemNumber={itemNumber} title={item.title} isDisabled={isDisabled} onClick={onClick}>
        <div class="input-group mb-3"  style={{width: "min(100%, 500px)"}}>
          <input 
            onChange={e => setValue(e.target.value)}
            value={value}
            className="form-control" 
            style={{pointerEvents: isDisabled ? "none" : ""}}
            />
            {
              !isLastItem && (
                <button class="btn btn-outline-secondary" onClick={(e) => {
                  e.stopPropagation();
                  onSelectNextItem()
                } }>Next</button>
              )
            }
        </div>
    </SurveyResponseSection>
  )
}

export default TextFieldResponse