import React from "react";

const SurveyResponseSection = ({ id, itemNumber, title, children, isDisabled, onClick, className="mt-1"}) => {
  return (
    <div id={id} className="mb-5 d-flex flex-rows gap-2" style={{opacity: isDisabled ? "20%" : null}} onClick={onClick}>
      <span>{itemNumber}.</span>
      <div className=" w-100">
        <span>{title}</span>
        <div className={className}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default SurveyResponseSection