import React from "react";
import { useParams } from "react-router-dom";
import Survey from '.'

const BrandedSurvey = () => {
  const params = useParams()
  return (
    <div className="container">
      <Survey surveyId={params.surveyId}/>
    </div>
  )
}

export default BrandedSurvey;