
import axios from 'axios';

let host = "https://api.ikeono.local"
if (process.env.REACT_APP_IKEONO_ENV === "staging") {
  host = "https://ik-api-staging.herokuapp.com/"
} else if (process.env.REACT_APP_IKEONO_ENV === 'production') {
  host = 'https://api.ikeono.com'
}

const axiosShared = axios.create({
  baseURL: host,
});

export const get = async (url, params) => {
  console.log(`API: GET: ${url}`, params)
  return axiosShared.get(url, {params})
}

export const post = async (url, data) => {
  console.log(`API: POST: ${url}`, data)
  return axiosShared.post(url, data)
}