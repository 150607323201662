/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SurveyResponseSection from "../SurveyResponseSection";
import "./RatingResponse.css"
import SkipButton from "./SkipButton";

const RatingButton = ({children, isEnd, isStart, isSelected, onClick}) => (
  <button onClick={e =>{ e.stopPropagation(); onClick()}} className={`rating-btn py-2 px-md-3 ${isSelected ? "rating-btn-selected" : ""} ${isEnd ? "rating-btn-end": ""} ${isStart ? "rating-btn-start" : ""}`}>{children}</button>
)

const RatingResponse = ({item, itemNumber, isDisabled, onClick, onSelectNextItem, onValueChange, showRequiredError, showSkipButton, topLeftTitle, topRightTitle}) => {
  const [value, setValue] = useState();

  useEffect(() => {
    onValueChange(item.id, value)
  }, [value])

  return (
    <SurveyResponseSection id={item.id} title={item.title} itemNumber={itemNumber} isDisabled={isDisabled} onClick={onClick}>
      <div className="d-inline-flex flex-column text-secondary">
        <div className="d-flex mb-1" style={{fontSize: "11px"}}>
          <div>{topLeftTitle}</div>
          <div className="ms-auto">{topRightTitle}</div>
        </div>
        <div className="d-inline-flex flex-rows" style={{pointerEvents: isDisabled ? "none" : null}}>
          <RatingButton isSelected={value===0} onClick={() => {setValue(0); onSelectNextItem()}} isStart>0</RatingButton>
          <RatingButton isSelected={value===1} onClick={() => {setValue(1); onSelectNextItem()}} >1</RatingButton>
          <RatingButton isSelected={value===2} onClick={() => {setValue(2); onSelectNextItem()}}>2</RatingButton>
          <RatingButton isSelected={value===3} onClick={() => {setValue(3); onSelectNextItem()}}>3</RatingButton>
          <RatingButton isSelected={value===4} onClick={() => {setValue(4); onSelectNextItem()}}>4</RatingButton>
          <RatingButton isSelected={value===5} onClick={() => {setValue(5); onSelectNextItem()}}>5</RatingButton>
          <RatingButton isSelected={value===6} onClick={() => {setValue(6); onSelectNextItem()}}>6</RatingButton>
          <RatingButton isSelected={value===7} onClick={() => {setValue(7); onSelectNextItem()}}>7</RatingButton>
          <RatingButton isSelected={value===8} onClick={() => {setValue(8); onSelectNextItem()}}>8</RatingButton>
          <RatingButton isSelected={value===9} onClick={() => {setValue(9); onSelectNextItem()}}>9</RatingButton>
          <RatingButton isSelected={value===10} onClick={() => {setValue(10); onSelectNextItem()}} isEnd>10</RatingButton>
        </div>
      </div>

      <div>
      {showSkipButton && (
        <SkipButton onSelectNextItem={onSelectNextItem} />
      )}
      {showRequiredError && <div className="text-danger mt-2">This questions is required</div>}
      </div>

    </SurveyResponseSection>
  )
}

export default RatingResponse