import React, { useEffect, useState } from "react";

const SurveyComplete = ({reviewLink, homeLink}) => {
  const [isRedirecting, setIsRedirecting] = useState();

  useEffect(() => {
    if (reviewLink) {
      setIsRedirecting(true)
      window.location.href = reviewLink
    }
  }, [reviewLink])

  if (isRedirecting) {
    return (
      <div className="text-center d-flex flex-column vh-100 justify-content-center align-items-center">
        <div className="spinner-border "></div>
        <span className="mt-2 text-secondary">One moment please</span>
      </div>
    )
  }

  return (
    <div className="mt-3 text-center d-flex flex-column">
      <h3 className="my-3">Thank you! 🎉</h3>
      <p>Your feedback helps us create a better experience for all of our customers.</p>
      {reviewLink  && (
        <a className="btn btn-primary mt-3 m-auto" href={reviewLink}  style={{width: "15rem"}} >Leave Google Review</a>
      )}
      {homeLink && (
        <a className="btn btn-sm btn-outline-primary mt-3 m-auto" style={{width: "15rem"}} href={homeLink}>Done</a>
      )}

    </div>
  )
}

export default SurveyComplete