import React from "react";


const SkipButton = ({className, onSelectNextItem}) => {
  return (
    <button 
      onClick={(e) => {
        e.stopPropagation()
        onSelectNextItem()
      }}
      className={`btn btn-link text-secondary ms-0 p-0 mb-0 mt-3 ${className}`} 
      style={{fontSize: "12px"}}
    >Skip, not applicable</button>
  )
}

export default SkipButton