/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import SurveyResponseSection from "../SurveyResponseSection";
import SkipButton from "./SkipButton";

const FreeFormResponse = ({item, isLastItem, itemNumber, isDisabled, onClick, onValueChange, onSelectNextItem}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    onValueChange(item.id, value)
  }, [value])
  
  return (
    <SurveyResponseSection id={item.id} itemNumber={itemNumber} title={item.title} isDisabled={isDisabled} onClick={onClick}>
      <div style={{width: "min(100%, 500px)"}}>
        <textarea 
          onChange={e => setValue(e.target.value)}
          value={value}
          className="form-control" 
          rows={3} 
          style={{pointerEvents: isDisabled ? "none" : ""}}
          />
          {
            !isLastItem && (
              <button class="btn btn-outline-secondary mt-2" onClick={(e) => {
                e.stopPropagation();
                onSelectNextItem()
              }}
              >Next</button>
            )
          }
      <SkipButton className="float-end" onSelectNextItem={onSelectNextItem} />

      </div>
    </SurveyResponseSection>
  )
}

export default FreeFormResponse