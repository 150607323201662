/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SurveyResponseSection from "../SurveyResponseSection";
import SkipButton from "./SkipButton";

const BooleanResponse = ({item, itemNumber, isDisabled, onClick, onSelectNextItem, onValueChange}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    onValueChange(item.id, value)
  }, [value])

  return (
    <SurveyResponseSection id={item.id} itemNumber={itemNumber} title={item.title} isDisabled={isDisabled} onClick={onClick} className="mt-3">
      <div style={{pointerEvents: isDisabled ? "none" : ""}}>
        <button 
          className={`btn ${value === false ? "btn-primary": 'btn-outline-primary'}  me-3`} 
          onClick={(e) => {
            e.stopPropagation();
            setValue(false); 
            onSelectNextItem()
          }}
        >No</button>
        <button 
          className={`btn ${value === true ? "btn-primary": 'btn-outline-primary'}`} 
          onClick={(e) => {
            e.stopPropagation();
            setValue(true); 
            onSelectNextItem();
          }}
        >Yes</button>
      </div>
      <SkipButton onSelectNextItem={onSelectNextItem} />
    </SurveyResponseSection>
  )
}

export default BooleanResponse