import { Route, Routes } from "react-router-dom";
import BrandedSurvey from "./Survey/BrandedSurvey";

function App() {
  return (
    <Routes>
      <Route path="/" index element={<h6 className="container text-center mt-5">Ikeono surveys</h6>}/>
      <Route path="/:surveyId" element={<BrandedSurvey/>} />
    </Routes>
    
  );
}

export default App;
